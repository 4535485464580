import Highcharts from 'highcharts';
import jQuery from "jquery";

export const Charts = () => {
jQuery(document).ready( function($) { 

    // Managed Events for Month
    if($('#managed_events_for_month').length) {
        Highcharts.chart('managed_events_for_month', {
            chart: {
                type: 'column',
                height: 410
            },
            title: null,
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                crosshair: true,
                accessibility: {
                    description: 'Months'
                },
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0.1,
                    borderWidth: 0
                }
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    name: 'Income',
                    data: [10000, 22000, 12000, 19300, 27500, 14500, 10000, 22000, 12000, 19300, 27500, 14500]
                },
            ]
        });
    }

    // Events types
    if($('#events_types').length) {
        Highcharts.chart('events_types', {
            chart: {
                type: 'pie',
                height: 180,
                spacing: [0,0,0,0]
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            title: null,
            legend: {
                enabled: false
            },
            dataLabels: {
                
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    borderRadius: 0,
                    dataLabels: [{
                        enabled: true,
                        distance: 0,
                        color: 'rgba(255,255,255,.5)',
                        format: '{point.name}<br>{point.percentage:.0f}%'
                    }],
                    showInLegend: true
                },
                pie: {
                    borderWidth: 0,
                    
                }
            },
            series: [{
                name: 'Events Types',
                colorByPoint: true,
                innerSize: '50%',
                data: [{
                    name: 'Flopped',
                    y: 68.1,
                    color: '#76A5DF'
                }, {
                    name: 'Booked',
                    y: 31.9,
                    color: '#0094C9'
                }]
            }]
        });
    }


    // Events Priority
    if($('#events_priority').length) {
        Highcharts.chart('events_priority', {
            chart: {
                type: 'pie',
                height: 180,
                spacing: [0,0,0,0]
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            title: null,
            legend: {
                enabled: false
            },
            dataLabels: {
                
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    borderRadius: 0,
                    dataLabels: [{
                        enabled: true,
                        distance: 0,
                        color: 'rgba(255,255,255,.5)',
                        format: '{point.name}<br>{point.percentage:.0f}%'
                    }],
                    showInLegend: true
                },
                pie: {
                    borderWidth: 0,
                    
                }
            },
            series: [{
                name: 'Events Types',
                colorByPoint: true,
                innerSize: '50%',
                data: [{
                    name: 'Flopped',
                    y: 22.5,
                    color: '#0442AA'
                }, {
                    name: 'Booked',
                    y: 77.5,
                    color: '#7640AE'
                }]
            }]
        });
    }

    // Incident Classes
    if($('#incident_classes').length) {
        Highcharts.chart('incident_classes', {
            chart: {
                type: 'column',
                height: 150                
            },
            title: null,
            xAxis: {
                categories: ['A', 'B', 'C', 'D', 'E'],
                crosshair: true,
                accessibility: {
                    description: 'Classes'
                },
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    name: '',
                    data: [
                        { y: 80, color: '#CED6FF' },
                        { y: 25, color: '#7FCEEB' },
                        { y: 20, color: '#0094C9' },
                        { y: 10, color: '#0442AA' },
                        { y: 15, color: '#08264B' }
                    ]
                },
            ]
        });
    }

    // TTPs
    if($('#ttps').length) {
        Highcharts.chart('ttps', {
            chart: {
                type: 'column',
                height: 150
            },
            title: null,
            xAxis: {
                categories: ['A', 'B', 'C', 'D', 'E'],
                crosshair: true,
                accessibility: {
                    description: 'Classes'
                },
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    name: '',
                    data: [
                        { y: 10, color: '#CED6FF' },
                        { y: 25, color: '#7FCEEB' },
                        { y: 20, color: '#0094C9' },
                        { y: 40, color: '#0442AA' },
                        { y: 15, color: '#08264B' }
                    ]
                },
            ]
        });
    }

    if($('#legit_activity').length) {
        Highcharts.chart('legit_activity', {
            chart: {
                type: 'bar',
                height: 300
            },
            title: null,
            xAxis: {
                categories: ['Item 1', 'Item 2', 'Item 3'],
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineWidth: 0,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                gridLineWidth: 1,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        enabled: false
                    },
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color:'#FFFFFF'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Series 1',
                data: [4, 8, 16],
                color: '#6CE5E8'
            }, {
                name: 'Series 2',
                data: [8, 14, 18],
                color: '#41B8D5'
            }]
        });
    }


    if($('#mitigated_activity').length) {
        Highcharts.chart('mitigated_activity', {
            chart: {
                type: 'bar',
                height: 300
            },
            title: null,
            xAxis: {
                categories: ['Item 1', 'Item 2', 'Item 3'],
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineWidth: 0,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                gridLineWidth: 1,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        enabled: false
                    },
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color:'#FFFFFF'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Series 1',
                data: [4, 8, 16],
                color: '#6CE5E8'
            }, {
                name: 'Series 2',
                data: [8, 14, 18],
                color: '#41B8D5'
            }]
        });
    }

    if($('#false_positive').length) {
        Highcharts.chart('false_positive', {
            chart: {
                type: 'bar',
                height: 300
            },
            title: null,
            xAxis: {
                categories: ['Item 1', 'Item 2', 'Item 3'],
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineWidth: 0,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                gridLineWidth: 1,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        enabled: false
                    },
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color:'#FFFFFF'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Series 1',
                data: [4, 8, 16],
                color: '#6CE5E8'
            }, {
                name: 'Series 2',
                data: [8, 14, 18],
                color: '#41B8D5'
            }]
        });
    }

    if($('#no_feedback').length) {
        Highcharts.chart('no_feedback', {
            chart: {
                type: 'bar',
                height: 300
            },
            title: null,
            xAxis: {
                categories: ['Item 1', 'Item 2', 'Item 3'],
                title: {
                    text: null
                },
                gridLineWidth: 0,
                lineWidth: 0,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            yAxis: {
                min: 0,
                title: null,
                gridLineWidth: 1,
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        enabled: false
                    },
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color:'#FFFFFF'
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Series 1',
                data: [2, 4, 12],
                color: '#6CE5E8'
            }, {
                name: 'Series 2',
                data: [8, 14, 18],
                color: '#41B8D5'
            }]
        });
    }

    if($('#performance_dash').length) {
        Highcharts.chart('performance_dash', {
            title: null,
            yAxis: {
                title: {
                    text: 'Number of Employees'
                },
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            xAxis: {
                labels: {
                    style: {
                        color:'#FFFFFF'
                    } 
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color:'#FFFFFF'
                }
            },
            series: [{
                name: 'Installation & Developers',
                data: [
                    43934, 48656, 65165, 81827, 112143, 142383,
                    171533, 165174, 155157, 161454, 154610, 168960, 171558
                ],
                color: '#6CE5E8'
            }, {
                name: 'Manufacturing',
                data: [
                    24916, 37941, 29742, 29851, 32490, 30282,
                    38121, 36885, 33726, 34243, 31050, 33099, 33473
                ],
                color: '#41B8D5'
            }, {
                name: 'Sales & Distribution',
                data: [
                    11744, 30000, 16005, 19771, 20185, 24377,
                    32147, 30912, 29243, 29213, 25663, 28978, 30618
                ],
                color: '#2D8BBA'
            }]        
        });
    }

})
}

export default Charts